<template>
  <div class="th-list-course">
    <div class="row">
      <div class="col-12 col-xl-6">
        <div class="card" style="height: 280px;border-radius: 40px;">
          <div class="d-flex justify-content-center h-100">
            <div class="w-100 h-100 d-flex flex-column justify-content-center text-left ">
              <div style="position: relative; height: 40%">
                <img src="../../assets/img/hello-widget.png" alt="" style="position: absolute;height: 100px;">
              </div>
              <div class="d-flex flex-column pl-5">
                <span style="font-size: 32px;" class="font-custom"><strong>Welcome to MODC</strong></span>
                <span style="font-size: 20px;" class="font-custom2">your alternative data center <br> operation</span>
              </div>
            </div>
            <div class=" w-100 h-100" style="position: relative;">
              <img src="../../assets/img/hello.png" alt=""
                style="position: absolute;height: 350px; top: -40px;right: -20px">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-3">
        <div class="card " style="height: 280px;border-radius: 40px; cursor: pointer"
          @click.prevent="goToAssets('expired')">
          <div class="d-flex justify-content-between " style="padding: 30px;">
            <span style="font-size: 40px" class="font-custom3">Expired <br> Assets</span>
            <span style="font-size: 40px" class="font-custom3">
              <img src="../../assets/img/navigate.png" alt="" style="height: 32px; ">
            </span>
          </div>
          <div class="d-flex justify-content-between" style="padding-left: 30px; padding-top: 0; position: relative;">
            <p style="font-size: 86px;margin: 0 !important; padding-top: 0 !important; line-height: 1;color: #DF6E77;"
              class="font-custom">{{ dataAggregate.expiredAsset }}</p>
            <img src="../../assets/img/expired-logo.png" alt=""
              style="height: 120px; position: absolute;right: -20px;bottom: -20px;">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-3">
        <div class="card " style="height: 280px;border-radius: 40px; cursor: pointer;"
          @click.prevent="goToAssets('expiring')">
          <div class="d-flex justify-content-between " style="padding: 30px;">
            <span style="font-size: 40px" class="font-custom3">Expiring <br> Assets</span>
            <span style="font-size: 40px" class="font-custom3">
              <img src="../../assets/img/navigate.png" alt="" style="height: 32px; ">
            </span>
          </div>
          <div class="d-flex justify-content-between" style="padding-left: 30px; padding-top: 0; position: relative;">
            <p style="font-size: 86px;margin: 0 !important; padding-top: 0 !important; line-height: 1; color: #FCA832;"
              class="font-custom">{{ dataAggregate.expiringAsset }}</p>
            <img src="../../assets/img/expiring-logo.png" alt=""
              style="height:120px; border-bottom-right-radius:40px; position: absolute; right: 0; bottom: -12px">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-6">
        <div class="card p-5" style="border-radius: 40px; height: 450px;">
          <p class="font-custom" style="font-size: 24px;">Asset Procurement and Maintenance by Year</p>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div style="width: 600px; height: 300px;">
              <canvas id="myChart4"></canvas>
            </div>
            <div style="font-family: Arial, sans-serif; font-size: 14px;" class=" d-flex flex-column mt-3">
              <div class="d-flex justify-content-center align-items-center">
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 170px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #68AC3D; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Total Procurement</div>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 170px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #FF9A2E; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Total Maintenance</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="card p-5" style="height: 450px;border-radius: 40px;cursor: pointer;"
          @click.prevent="goToAssets('vendor')">
          <div class="d-flex justify-content-between align-items-baseline">
            <p class="font-custom" style="font-size: 24px;">List Vendor</p>
            <p class="font-custom2" style="font-size: 18px;">Lihat Semua</p>
          </div>
          <div class=" mt-3 px-4 d-flex justify-content-between align-items-center"
            style="height: 80px;border-radius: 20px;" v-for="(item, idx) in topVendor" :key="item.name"
            :style="{ 'background-color': idx === 0 ? '#C1D9B2' : idx === 1 ? '#FCE3C8' : '#E9E9E9' }">
            <div class="d-flex">
              <div class="mr-4 text-white d-flex justify-content-center align-items-center"
                style="height: 35px; width: 35px; border-radius: 10px;"
                :style="{ 'background-color': idx === 0 ? '#68AC3D' : idx === 1 ? '#FF9A2E' : '#C0C0C0' }">
                <span style="font-size: 20px" class="font-custom">{{ idx + 1 }}</span>
              </div>
              <p class="font-custom" style="font-size: 24px; margin: 0 !important; padding: 0 !important;">
                {{ item ? item.name : '-' }}
              </p>
            </div>
            <p class="font-custom" style="font-size: 24px;margin: 0 !important; padding: 0 !important;">{{ item.value }}
            </p>
          </div>
          <!-- <div class=" mt-4 px-4 d-flex justify-content-between align-items-center"
            style="height: 80px;border-radius: 20px;background-color: #FCE3C8;">
            <div class="d-flex">
              <div class="mr-4 text-white d-flex justify-content-center align-items-center"
                style="height: 35px; width: 35px; border-radius: 10px;background-color: #FF9A2E;">
                <span style="font-size: 20px" class="font-custom">2</span>
              </div>
              <p class="font-custom" style="font-size: 24px; margin: 0 !important; padding: 0 !important;">Nama
                Vendor
              </p>
            </div>
            <p class="font-custom" style="font-size: 24px;margin: 0 !important; padding: 0 !important;">120 M</p>
          </div>
          <div class=" mt-4 px-4 d-flex justify-content-between align-items-center"
            style="height: 80px;border-radius: 20px;background-color: #c;">
            <div class="d-flex">
              <div class="mr-4 text-white d-flex justify-content-center align-items-center"
                style="height: 35px; width: 35px; border-radius: 10px;background-color: #C0C0C0;">
                <span style="font-size: 20px" class="font-custom">3</span>
              </div>
              <p class="font-custom" style="font-size: 24px; margin: 0 !important; padding: 0 !important;">Nama
                Vendor
              </p>
            </div>
            <p class="font-custom" style="font-size: 24px;margin: 0 !important; padding: 0 !important;">120 M</p>
          </div> -->
        </div>
      </div>

      <div class="col-xl-4 d-inline-block" style="cursor: pointer;" @click.prevent="goToAssets('assetOnly')">
        <div class="card" style=" min-height: 500px;border-radius: 40px">

          <!-- <h2 class="text-uppercase text-dark font-weight-bold mb-1 text-center"> {{ dataAggregate.totalAsset }}
              </h2>
              <p class="text-gray-5 text-center mb-0">Jumlah seluruh aset</p> -->
          <div class=" px-5 pt-5">
            <h2 class="text-uppercase text-dark font-weight-bold mb-1 font-custom"> {{ dataAggregate.totalAsset }}
            </h2>
            <p class="text-gray-5 mb-0 font-custom2">Total Asset</p>

          </div>
          <!-- <p class="text-center font-custom" style="font-size: 26px;">Total Asset</p> -->
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div style="width: 292px; height: 292px;">
              <canvas id="myChart3"></canvas>
            </div>
            <div style="font-family: Arial, sans-serif; font-size: 14px;" class=" d-flex flex-column mt-5 mb-5">
              <div class="d-flex justify-content-center align-items-center">
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #B0DE94; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Active Devices</div>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #FE91B2; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Passive D.</div>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 90px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #9B88D1; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Facilities</div>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-3">
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #6DA8C3; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Monitoring</div>

                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #F7EB85; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Infrastructure</div>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 90px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #B96479; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Software</div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-xl-4 d-inline-block" style="cursor: pointer;" @click.prevent="goToAssets('assetOnly')">
        <div class="card" style=" min-height: 500px;border-radius: 40px">

          <!-- <h2 class="text-uppercase text-dark font-weight-bold mb-1 text-center"> {{ dataAggregate.totalAsset }}
              </h2>
              <p class="text-gray-5 text-center mb-0">Jumlah seluruh aset</p> -->
          <div class=" px-5 pt-5">
            <h2 class="text-dark font-weight-bold mb-1">{{
            dataAggregate?.assetValue?.total.toLocaleString('id-ID') }}</h2>
            <p class="text-gray-5 mb-0 font-custom2">Total Value Seluruh Asset</p>

          </div>
          <!-- <p class="text-center font-custom" style="font-size: 26px;">Total Asset</p> -->
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div style="width: 292px; height: 292px;">
              <canvas id="myChart"></canvas>
            </div>
            <div style="font-family: Arial, sans-serif; font-size: 14px;" class=" d-flex flex-column mt-5 mb-5">
              <div class="d-flex justify-content-center align-items-center">
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #98DB6E; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Active Devices</div>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #FFC88F; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Passive D.</div>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 90px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #FF8F8F; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Facilities</div>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-3">
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #FFA6B8; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Monitoring</div>

                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #BF8CC3; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Infrastructure</div>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 90px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #EF6767; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Software</div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-xl-4 d-inline-block" style="cursor: pointer;" @click.prevent="goToAssets('assetOnly')">
        <div class="card" style=" min-height: 500px;border-radius: 40px">

          <!-- <h2 class="text-uppercase text-dark font-weight-bold mb-1 text-center"> {{ dataAggregate.totalAsset }}
              </h2>
              <p class="text-gray-5 text-center mb-0">Jumlah seluruh aset</p> -->
          <div class=" px-5 pt-5">
            <!-- <h2 class="text-dark font-weight-bold mb-1">42</h2> -->
            <h2 class="text-dark font-weight-bold mb-1">{{
            dataAggregate?.totalChangeRequest }}</h2>
            <p class="text-gray-5 mb-0 font-custom2">Total Change Request</p>
          </div>
          <!-- <p class="text-center font-custom" style="font-size: 26px;">Total Asset</p> -->
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div style="width: 292px; height: 292px;">
              <canvas id="myChart2"></canvas>
            </div>
            <div style="font-family: Arial, sans-serif; font-size: 14px;" class=" d-flex flex-column mt-5 mb-5">
              <div class="d-flex justify-content-center align-items-center">
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #FFC07F; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Addition</div>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #B3DA9B; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Troubleshooting</div>

                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-3">
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #D397D8; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Dismantling</div>

                </div>
                <div style="display: flex; align-items: center; margin-bottom: 4px; width: 135px;">
                  <div
                    style="width: 16px; height: 16px;border-radius: 5px; background-color: #FE6383; margin-right: 5px;">
                  </div>
                  <div class="font-custom3 ml-1">Testing</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import { onMounted, onBeforeUnmount, ref, reactive, createVNode, toRef, toRefs, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { baseURL } from '@/services/axios'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  SearchOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  ExclamationCircleOutlined,
  CloseSquareOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
} from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'
import {
  getAddress,
  getAll,
  searchPotensi,
  remove,
  updateNasabah,
  getAllKanwil,
  getOneKC,
  getDistinct,
} from '@/services/axios/brivice-backend'
import fuzzysort from 'fuzzysort'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import moment from 'moment'
import lodash from 'lodash'
import assetsFile from '@/data/modc_dump.json'
import { getAssets, getCustomer } from '../../services/axios/modc/assets'
import { getDashboardData } from '../../services/axios/modc/dashboard'
import { getRooms, getLocationByCustomerId } from '../../services/axios/modc/customer'
import { getLocations } from '../../services/axios/modc/users'
import lottie from '../../../scripts/Timer.json'
import caution from '../../../scripts/Caution.json'

import {
  getVisitors, outVisitor, VisitorIn, cancelVisitors,
} from '../../services/axios/modc/receptionist'
// CHART
import { Chart, registerables } from "chart.js";
import { Vue3Lottie } from 'vue3-lottie'
const legendPaddingPlugin = {
  id: 'legendPaddingPlugin',
  beforeInit(chart) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      chart.legend.height += 20; // Menyesuaikan ini sesuai dengan jumlah padding yang Anda inginkan
    };
  }
};
Chart.register(...registerables, legendPaddingPlugin);
const columns = [
  {
    title: 'ID',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
    width: 100,
    sorter: true,
  },
  {
    title: 'LOKASI',
    dataIndex: 'location',
    key: 'location',
    slots: { customRender: 'location' },
  },
  {
    title: 'MANUFAKTUR',
    dataIndex: 'manufacture',
    key: 'manufacture',
    slots: { customRender: 'manufacture' },
  },
  {
    title: 'CUSTOMER',
    dataIndex: 'customer',
    key: 'customer',
    slots: { customRender: 'customer' },
  },
  {
    title: 'STATUS GARANSI',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'status' },
  },
  {
    title: 'STATUS ASSET',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'statusAsset' },
  },

  {
    title: 'NILAI ASET',
    dataIndex: 'assetValue',
    key: 'assetValue',
    slots: { customRender: 'assetValue' },
  },
]
const columnsReceptionist = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },


  {
    title: 'Vendor',
    dataIndex: 'Vendor.name',
    key: 'category',
    // slots: { customRender: 'description' },
  },
  {
    title: 'Status',
    key: 'out',
    align: 'center',
    slots: { customRender: 'status' },
  },
  // {
  //   title: 'PIC',
  //   dataIndex: 'PIC',
  //   key: 'category',
  //   // slots: { customRender: 'description' },
  // },
  // {
  //   title: 'PIC',
  //   dataIndex: 'PIC',
  //   key: 'category',
  //   // slots: { customRender: 'description' },
  // },
  // {
  //     title: 'Keterangan',
  //     key: 'ceklis',
  //     slots: { customRender: 'action' },
  //     align: 'center',
  // },
];

export default {
  components: {
    CloudUploadOutlined,
    CloudDownloadOutlined,
    InfoCircleOutlined,
    CheckOutlined,
    ToolOutlined,
    PhoneOutlined,
    HomeOutlined,
    ContactsOutlined,
    Vue3Lottie,
    // BarChart
  },
  setup() {
    /* eslint-disable */
    let store = useStore()
    let route = useRoute()
    let settings = computed(() => store.getters.settings)
    // console.log(store.state.user, 'usercourse')
    let courseData = ref([])
    let modalPopUpVisibleBrispot = ref(false)
    let modalPopUpVisibleKurasi = ref(false)
    let selectedKanwil = ref(null)
    let isNasabah = ref(false)
    selectedKanwil.value = 'all'
    let potensiData = ref([])
    let indukPerusahaan = ref([])
    let isSearching = ref(false)
    let totalPages = ref([])
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let search = ref('')
    let filterLokasi = ref([])
    let filterCustomer = ref([])
    let selectedFilterLokasi = ref('Filter Lokasi')
    let selectedFilterCustomer = ref('Filter Customer')
    let filterRuang = ref([])
    let selectedFilterRuang = ref('Filter Ruang')
    let selectedFilterStatus = ref('Filter Status Asset')
    let selectedFilterStatusGaransi = ref('Filter Status Garansi')
    let realData = ref([])
    const filterYear = ref(moment().year())
    const changeYear = value => {
      console.log(moment(value).year())
      filterYear.value = moment(value).year()
    };

    const filterStatusGaransi = async (e) => {
      // await fetchCourse()
      // console.log(e)
      filteredData.value = realData.value
      if (e === 'Belum Di input') {
        filteredData.value = realData.value.filter(e => e.statusGaransi === 'Belum Di input')
      } else if (e === 'Garansi Aktif') {
        filteredData.value = realData.value.filter(e => e.statusGaransi === 'Garansi Aktif')
      } else if (e === 'Segera Berakhir') {
        filteredData.value = realData.value.filter(e => e.statusGaransi === 'Segera Berakhir')
      } else if (e === 'Garansi Berakhir') {
        filteredData.value = realData.value.filter(e => e.statusGaransi === 'Garansi Berakhir')
      } else {
        filteredData.value = realData.value
      }
      console.log(filteredData.value, 'ini datanya')

    }

    const acc_token = ref(null)
    let filteredData = ref([])
    let loadingTable = ref(false)
    let sortRef = ref(null)
    let filter = reactive({
      name: null,
    })
    let pageRef = ref(null)
    let modalAddVisible = ref(false)
    const loadingAdd = ref(false)
    const router = useRouter()
    const dataAggregate = ref({
      totalVendors: 0, // V
      totalUsers: 0, // V
      totalCustomers: 0, // V
      totalLocations: 0, // V
      totalAsset: 0, // V
      totalProcurement: 0,
      totalMaintenance: 0,
      totalProcurementCost: [{ year: 2024, cost: 4 }],
      totalMaintenanceCost: [{ year: 2024, cost: 4 }],
      totalChangeRequest: 0, // V
      activeAsset: 0, // V
      pendingAsset: 0, // V
      troubleshootAsset: 0, // V
      dismantleAsset: 0, // V
    })
    const role = ref('')
    let dataReceptionist = ref([])
    const chartInstance = ref(null);
    // let myChart4 = null;
    onMounted(async () => {
      const storedUserData = localStorage.getItem('userData')
      const userData = JSON.parse(storedUserData)
      role.value = userData.user.role
      fetchCourse()

    })
    onBeforeUnmount(() => {
      if (chartInstance.value) {
        chartInstance.value.destroy();
        chartInstance.value = null;
      }
    });
    const onChangePage = page => {
      if (!isSearching.value) {
        fetchCourse(page)
      }
    }

    const onFilterMenuChange = value => {
      if (value) {
        console.log(potensiData.value, '<<<<<<<<<<<<<')
        // filteredData.value = potensiData.value.filter(d => d.indukPerusahaan === value)
        fetchCourse({ filter: value })
      } else {
        // filteredData.value = potensiData.value
        fetchCourse()
      }
      // potensiData.value = potensiList.result.filter(d => d.status === 'active')
    }

    let vendors = ref([])
    let categories = ref([])

    const onSearch = async searchText => {
      if (searchText !== '') {
        isSearching.value = true
        loadingTable.value = true
        const res = await searchPotensi(searchText)
        console.log(res, 'res search')
        // const results = fuzzysort.go(searchText, potensiData.value, { key: 'name' })
        // const sortData = results.map(result => {
        //     return result.obj
        // })
        filteredData.value = res.result
        loadingTable.value = false
      } else {
        isSearching.value = false
        loadingTable.value = false
        fetchCourse(1)
      }
    }
    const getCurrentDate = computed(() => moment())
    // watch([selectedFilterLokasi, selectedFilterRuang, selectedFilterCustomer, selectedFilterStatus, filterYear], (to, from) => {
    //   // console.log(selectedFilterLokasi.value, '<<<<<<<<')
    //   console.log('ngulang>')
    //   fetchRooms(selectedFilterLokasi.value)
    //   fetchCourse(search.value, filterYear.value)
    //   fetchLokasi(selectedFilterCustomer.value)

    // })
    const topVendor = ref([])
    const fetchCourse = async (search, year) => {
      loadingTable.value = true
      // const { value: sort } = sortRef
      // const { value: page } = pageRef
      // const { value: status } = statusPublication
      // const name = filter.name

      // const kanwils = await getAllKanwil()
      // kanwilData.value = kanwils.result

      // const potensiList = await getAll({
      //   p: globalPagination.value.current,
      //   search: search.value,
      // })
      // console.log(potensiList, '<<<<<<<<<')
      const storedUserData = localStorage.getItem('userData')
      const userData = JSON.parse(storedUserData)
      const res = await getAssets({}, selectedFilterCustomer.value, selectedFilterLokasi.value, selectedFilterRuang.value, selectedFilterStatus.value, userData.user.id, userData.user.role, search)
      const aggregate = await getDashboardData(year, userData.user.role, userData.user.id)
      console.log(aggregate.topVendor, 'initopvendor')
      topVendor.value = aggregate.topVendor
      dataAggregate.value = aggregate
      dataAggregate.value.totalProcurement = aggregate.totalProcurement
      dataAggregate.value.totalMaintenance = aggregate.totalMaintenance
      // dataAggregate.value.totalMaintenance = aggregate.totalMaintenance.find(
      //   e => e.year == moment().year(),
      // )
      // dataAggregate.value.totalProcurementCost = aggregate.totalProcurementCost.find(
      //   e => e.year == moment().year(),
      // )
      // dataAggregate.value.totalMaintenanceCost = aggregate.totalMaintenanceCost.find(
      //   e => e.year == moment().year(),
      // )
      // console.log(aggregate, '<<<<AGGREGATE')
      // let filteredPotensi = potensiList.result.filter(d => d.status === 'active')
      // // filteredData.value = lodash.sortBy(filteredPotensi, ['ahuPercentage', 'placePercentage','name'])
      if (res.assets.length) {
        filteredData.value = res.assets.filter(
          asset => ![1, 2, 4, 9, 12, 5, 6].includes(asset.assetSubCategoryId),
        )
        realData.value = res.assets.filter(
          asset => ![1, 2, 4, 9, 12, 5, 6].includes(asset.assetSubCategoryId),
        )

      } else {
        filteredData.value = []
      }
      const { customer } = await getCustomer()
      console.log(customer, 'disiniajalah')
      // const { locations } = await getLocations()
      // filterLokasi.value = locations.map(e => ({ name: e.name, id: e.id }))
      filterCustomer.value = customer.map(e => ({ name: e.name, id: e.id }))
      // potensiData.value = potensiList.result.filter(d => d.status === 'active')
      // filteredData.value = filteredData.value.map(el => {
      //   return {
      //     key: el._id,
      //     ...el,
      //   }
      // })
      // acc_token.value = localStorage.getItem('access_token')
      // if (selectedIndukPerusahaan.value) {
      //   filteredData.value = potensiList.result.filter(d => d.indukPerusahaan === selectedIndukPerusahaan.value)
      // }
      console.log(filteredData.value, '<<<<<<ASJNDLKMALNDJKLKAM')
      loadingTable.value = false
      isSearching.value = false
      chartAssets.value.labels = aggregate.chartAssets.labels
      chartAssets.value.datasets[0].data = aggregate.chartAssets.data
      chartChangeRequest.value.labels = aggregate.chartChangeRequest.labels
      chartChangeRequest.value.datasets[0].data = aggregate.chartChangeRequest.data
      const ctx = document.getElementById('myChart');
      // console.log(dataAggregate.value)
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [
            'Active Devices',
            'Passive Devices',
            'Facilities',
            'Software',
            'Infrastructure',
            'Monitoring',
          ],
          datasets: [{
            label: 'My First Dataset',
            // data: [10000, 20000, 15000, 25000, 30000, 28000],
            data: [dataAggregate.value.assetValue['Active Devices'] ? dataAggregate.value.assetValue['Active Devices'] : 0, dataAggregate.value.assetValue['Passive Devices'] ? dataAggregate.value.assetValue['Passive Devices'] : 0, dataAggregate.value.assetValue['Facilities'] ? dataAggregate.value.assetValue['Facilities'] : 0, dataAggregate.value.assetValue['Software'] ? dataAggregate.value.assetValue['Software'] : 0, dataAggregate.value.assetValue['Infrastructure'] ? dataAggregate.value.assetValue['Infrastructure'] : 0, dataAggregate.value.assetValue['Monitoring'] ? dataAggregate.value.assetValue['Monitoring'] : 0],
            backgroundColor: ["#98DB6E", "#FFC88F", "#FF8F8F", "#EF6767", "#BF8CC3", "#FFA6B8"],

            hoverOffset: 4,
          }]
        },
        options: {
          cutout: '70%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  let label = tooltipItem.chart.data.labels[tooltipItem.dataIndex];
                  let value = tooltipItem.raw;
                  // Format value sebagai string uang
                  return label + ': Rp' + value.toLocaleString('id-ID');
                }
              }
            },
            legend: {
              display: false
            }
          }
        }
      });

      const ctx2 = document.getElementById('myChart2');
      new Chart(ctx2, {
        type: 'doughnut',
        data: {
          labels: dataAggregate.value.chartChangeRequest.labels,
          datasets: [{
            label: 'My First Dataset',
            data: dataAggregate.value.chartChangeRequest.data,
            // data: ['1200', '800', '900', '720'],
            backgroundColor: [
              '#B3DA9B',
              '#FFC07F',
              '#D397D8',
              '#FE6383',
            ],
            hoverOffset: 4,
          }]
        },
        Width: 1,
        options: {
          cutout: '70%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          },
          onClick: function (event, elements) {
            if (elements.length > 0) {
              const firstElement = elements[0];
              const label = this.data.labels[firstElement.index];
              const value = this.data.datasets[firstElement.datasetIndex].data[firstElement.index];
              console.log(`Label: ${label}, Value: ${value}`);
            }
          },
        }
      });

      const { visitors } = await getVisitors(userData.user.id, moment(new Date()).format('YYYY-MM-DD'))
      visitors.forEach((e, idx) => {
        e.status = 'booked'
        e.key = idx + 1
        if (e.inTime) {
          e.inTime = moment(e.inTime).format('HH:mm A DD-MM-YYYY')
          e.status = 'in'
        }
        if (e.outTime) {
          e.outTime = moment(e.outTime).format('HH:mm A DD-MM-YYYY')
          e.status = 'out'
        }
      })
      dataReceptionist.value = visitors
      const ctx3 = document.getElementById('myChart3').getContext('2d');
      new Chart(ctx3, {
        type: 'doughnut',
        data: {
          labels: [
            'Active Devices',
            'Passive Devices',
            'Facilities',
            'Software',
            'Infrastructure',
            'Monitoring',
          ],
          datasets: [{
            label: 'My First Dataset',
            data: dataAggregate.value.chartAssets.data,
            backgroundColor: ["#B0DE94", "#FE91B2", "#9B88D1", "#B96479", "#F7EB85", "#6DA8C3"],
            hoverOffset: 4,
            // borderColor: '#FFFFFF',
            // borderWidth: 10,
          }]
        },
        options: {
          cutout: '70%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          },
        },
        // plugins: [{
        //   id: 'customCenterText',
        //   beforeDraw: (chart) => {
        //     const ctx = chart.ctx;
        //     const width = chart.innerWidth;
        //     const height = chart.innerHeight;
        //     const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        //     const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

        //     ctx.save();
        //     ctx.font = '20px Rubik';
        //     ctx.fillStyle = '#333';
        //     ctx.textAlign = 'center';
        //     ctx.textBaseline = 'middle';
        //     ctx.fillText(`Total Assets : ${dataAggregate.value.totalAsset}`, centerX, centerY);
        //     ctx.restore();
        //   }
        // }]
      });


      const ctx4 = document.getElementById('myChart4');
      chartInstance.value = new Chart(ctx4, {
        type: 'line',
        data: {
          labels: dataAggregate.value.yearsDataSets,
          datasets: [
            {
              label: 'Total Procurement',
              data: dataAggregate.value.totalDataSetsProcurements,
              borderColor: '#68AC3D',
              backgroundColor: '#68AC3D',
              yAxisID: 'y',
              borderWidth: 5,
              tension: 0.4,
            },
            {
              label: 'Total Maintenance',
              data: dataAggregate.value.totalDataSetsMaintenances,
              borderColor: '#FF9A2E',
              backgroundColor: '#FF9A2E',
              yAxisID: 'y',
              borderWidth: 5,
              tension: 0.4,
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          stacked: false,
          plugins: {
            legend: {
              display: false // Menonaktifkan legenda
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ' + context.parsed.y;
                  }
                  return label;
                },
                afterLabel: function (context) {
                  const totalCostProcurement = dataAggregate.value.totalDataSetsCostProcurements;
                  const totalCostMaintenance = dataAggregate.value.totalDataSetsCostMaintenances;
                  let totalCostArray = context.datasetIndex === 0 ? totalCostProcurement : totalCostMaintenance;
                  let additionalInfo = 'Total Cost: ' + totalCostArray[context.dataIndex];
                  return additionalInfo;
                }
              },
              titleFontSize: 16,
              titleFontColor: '#0066ff',
              bodyFontColor: '#000',
              bodyFontSize: 14,
              displayColors: true,
              borderColor: 'gray',
              borderWidth: 1
            }
          },
          scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              grid: {
                color: '#E9E9E9' // Change the grid line color here
              }
            },
            x: {
              type: 'category',
              grid: {
                color: '#E9E9E9' // Change the grid line color here
              }
            }
          }
        }
      });


      // chartInstance.value = new Chart(ctx4, {
      //   type: 'bar', // Menggunakan tipe bar chart sebagai dasar
      //   data: {
      //     labels: dataAggregate.value.yearsDataSets,
      //     datasets: [
      //       {
      //         label: 'Total Procurement',
      //         data: dataAggregate.value.totalDataSetsProcurements,
      //         borderColor: '#153C87',
      //         backgroundColor: 'rgba(21, 60, 135, 0.5)',
      //         yAxisID: 'y',
      //         borderWidth: 3,
      //         type: 'bar', // Tipe dataset spesifik ini sebagai bar
      //         order: 1 // Order digunakan untuk kontrol rendering, bar akan dibawah line
      //       },
      //       {
      //         label: 'Total Maintenance',
      //         data: dataAggregate.value.totalDataSetsMaintenances,
      //         borderColor: '#E91E63', // Pink Cerah
      //         backgroundColor: 'rgba(233, 30, 99, 0.5)',
      //         yAxisID: 'y1',
      //         borderWidth: 3,
      //         // tension: 0.4,
      //         type: 'line', // Tipe dataset spesifik ini sebagai line
      //         order: 2 // Line akan diatas bar
      //       }
      //     ]
      //   },
      //   options: {
      //     responsive: true,
      //     stacked: true, // Mengaktifkan stacking
      //     plugins: {
      //       title: {
      //         display: false,
      //       },
      //       tooltip: {
      //         callbacks: {
      //           label: function (context) {
      //             let label = context.dataset.label || '';
      //             if (label) {
      //               label += ': ' + context.parsed.y;
      //             }
      //             return label;
      //           },
      //           afterLabel: function (context) {
      //             const totalCostProcurement = dataAggregate.value.totalDataSetsCostProcurements;
      //             const totalCostMaintenance = dataAggregate.value.totalDataSetsCostMaintenances;
      //             let totalCostArray;
      //             if (context.datasetIndex === 0) {
      //               totalCostArray = totalCostProcurement;
      //             } else if (context.datasetIndex === 1) {
      //               totalCostArray = totalCostMaintenance;
      //             }
      //             let additionalInfo = 'Total Cost: ' + totalCostArray[context.dataIndex];
      //             return additionalInfo;
      //           }
      //         },
      //         titleFontSize: 16,
      //         titleFontColor: '#0066ff',
      //         bodyFontColor: '#000',
      //         bodyFontSize: 14,
      //         displayColors: true,
      //         borderColor: 'gray',
      //         borderWidth: 1
      //       }
      //     },
      //     scales: {
      //       y: {
      //         type: 'linear',
      //         display: true,
      //         position: 'left',
      //         stacked: true // Stacking untuk axis y
      //       },
      //       y1: {
      //         type: 'linear',
      //         display: true,
      //         position: 'right',
      //         stacked: false // Tidak stacking untuk axis y1, karena itu adalah line chart
      //       },
      //     }
      //   }
      // });
      // chartInstance.value = new Chart(ctx4, {
      //   type: 'bar', // Tipe chart utama sebagai bar
      //   data: {
      //     labels: dataAggregate.value.yearsDataSets, // Label tahun
      //     datasets: [
      //       {
      //         label: 'Total Procurement',
      //         data: dataAggregate.value.totalDataSetsProcurements,
      //         backgroundColor: '#153C86',
      //         yAxisID: 'y',
      //         borderWidth: 0,
      //         type: 'bar', // Pastikan ini juga diatur sebagai bar
      //         order: 1
      //       },
      //       {
      //         label: 'Total Maintenance',
      //         data: dataAggregate.value.totalDataSetsMaintenances,
      //         backgroundColor: '#4B7CF3',
      //         yAxisID: 'y1',
      //         borderWidth: 0,
      //         type: 'bar', // Ini juga diatur sebagai bar untuk konsistensi
      //         order: 2
      //       }
      //     ]
      //   },
      //   options: {
      //     responsive: true,
      //     plugins: {
      //       title: {
      //         display: false,
      //       },
      //       tooltip: {
      //         callbacks: {
      //           label: function (context) {
      //             let label = context.dataset.label || '';
      //             if (label) {
      //               label += ': ' + context.parsed.y;
      //             }
      //             return label;
      //           },
      //           afterLabel: function (context) {
      //             const totalCostProcurement = dataAggregate.value.totalDataSetsCostProcurements;
      //             const totalCostMaintenance = dataAggregate.value.totalDataSetsCostMaintenances;
      //             let totalCostArray;
      //             if (context.datasetIndex === 0) {
      //               totalCostArray = totalCostProcurement;
      //             } else if (context.datasetIndex === 1) {
      //               totalCostArray = totalCostMaintenance;
      //             }
      //             let additionalInfo = 'Total Cost: ' + totalCostArray[context.dataIndex];
      //             return additionalInfo;
      //           }
      //         },
      //         titleFontSize: 16,
      //         titleFontColor: '#0066ff',
      //         bodyFontColor: '#000',
      //         bodyFontSize: 14,
      //         displayColors: true,
      //         borderColor: 'gray',
      //         borderWidth: 1
      //       }
      //     },
      //     scales: {
      //       y: {
      //         type: 'linear',
      //         display: true,
      //         position: 'left'
      //       },
      //       y1: {
      //         type: 'linear',
      //         display: true,
      //         position: 'right'
      //       },
      //     }
      //   }
      // });

      globalPagination.value = { ...globalPagination.value, total: res.assets.length }
    }
    const fetchRooms = async (id = '') => {
      const { rooms } = await getRooms('', id)
      filterRuang.value = rooms
    }
    const fetchLokasi = async (id = '') => {
      // console.log(id, 'ini id customer buat lokasi')
      const { locations } = await getLocations('', id)
      filterLokasi.value = locations
    }
    const getStatusWarranty = record => {
      // console.log(record, 'apacb')
      // console.log(record.id, 'iniidyangdariitu')

      const procurement = record.ProcurementInformation
      const maintenance = record.MaintenanceInformations
      if (maintenance[0]?.warrantyStart) {
        // console.log('masukmt')
        const currentDate = moment()
        const mtLength = maintenance.length - 1
        const startDateMoment = moment(maintenance[mtLength].warrantyStart)
        const endDateMoment = moment(maintenance[mtLength].warrantyEnd)
        let statusWarranty
        if (currentDate.isAfter(endDateMoment)) {
          statusWarranty = 'Garansi Berakhir'
        } else if (currentDate.isAfter(endDateMoment.clone().subtract(3, 'months').toDate())) {
          statusWarranty = 'Segera Berakhir'
        } else if (currentDate.isBefore(endDateMoment.clone().subtract(3, 'months').toDate())) {
          statusWarranty = 'Garansi Aktif'
        }
        const startDateValidation = startDateMoment.clone().isValid()
        const startDate = startDateValidation ? startDateMoment.clone().format('DD / MM / YYYY') : '-'
        const endDateValidation = endDateMoment.clone().isValid()
        const endDate = endDateValidation ? endDateMoment.clone().format('DD / MM / YYYY') : '-'
        record.statusGaransi = statusWarranty ? statusWarranty : 'Belum Di input'

        return {
          statusWarranty: statusWarranty ? statusWarranty : 'Belum Di input',
          startDate: startDate,
          endDate: endDate,
        }
      }
      else if (procurement?.warrantyStart) {
        // console.log('masuk proc')
        const currentDate = moment()

        const startDateMoment = moment(procurement.warrantyStart)
        const endDateMoment = moment(procurement.warrantyEnd)
        const calculatedEndDate = startDateMoment.clone().add(procurement.warranty, 'months')
        let statusWarranty
        if (currentDate.isAfter(calculatedEndDate)) {
          statusWarranty = 'Garansi Berakhir'
        } else if (currentDate.isAfter(endDateMoment.clone().subtract(3, 'months').toDate())) {
          statusWarranty = 'Segera Berakhir'
        } else if (currentDate.isBefore(endDateMoment.clone().subtract(3, 'months').toDate())) {
          statusWarranty = 'Garansi Aktif'
        }
        const startDateValidation = startDateMoment.clone().isValid()
        const startDate = startDateValidation ? startDateMoment.clone().format('DD / MM / YYYY') : '-'
        const endDateValidation = endDateMoment.clone().isValid()
        const endDate = endDateValidation ? endDateMoment.clone().format('DD / MM / YYYY') : '-'
        // record.statusGaransi = statusWarranty ? statusWarranty : 'Belum Di input'
        return {
          statusWarranty: statusWarranty ? statusWarranty : 'Belum Di input',
          startDate: startDate,
          endDate: endDate,
        }
      } else {
        return {
          statusWarranty: 'Belum Di input',
          startDate: '-',
          endDate: '-',
        }
      }
      // console.log(procurement, ',<<<<', '>>>>>', maintenance)
    }
    const convertPrice = value => {
      return rupiahPrice(value)
    }

    const lodashSearch = lodash.debounce(() => fetchCourse(search.value), 1500)
    const handleSearch = () => {
      globalPagination.value.current = 1
      lodashSearch()
      // fetchCourse({ search: search.value })
    }
    const handleTableChange = (pag, filters, sorter) => {
      globalPagination.value.current = pag.current
      // if (sorter) {
      //   if (sorter.columnKey == 'key') sortBy.value = '_id'
      //   else sortBy.value = sorter.columnKey
      //   order.value = sorter.order
      // }
      fetchCourse()
    }

    const goTo = (name, params) => {
      router.push({ name, params })
    }
    const handleOk = () => {
      modalAddVisible.value = false
    }

    const chartAssets = ref({
      labels: ['Paris', 'Nîmes', 'Toulon', 'Perpignan', 'Autre'],
      datasets: [
        {
          data: [30, 40, 60, 70, 5],
          backgroundColor: ['#89DAFF', '#81F0E5', '#A6D9F7', '#4DFFF3', '#D9F7FA', '#BFDBF7'],
        },
      ],

    });
    const optionsAssets = ref({
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          display: false
        },
        title: {
          display: true,
          text: 'Assets',
        },
      },
    });
    const chartChangeRequest = ref({
      labels: ['Paris', 'Nîmes', 'Toulon', 'Perpignan', 'Autre'],
      datasets: [
        {
          data: [30, 40, 60, 70, 5],
          backgroundColor: ['#E59500', '#FFD25A', '#F9C846 ', '#F39C6B'],
        },
      ],

    });
    const optionsChangeRequest = ref({
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          display: false
        },
        title: {
          display: true,
          text: 'Change Request',
        },
      },
    });
    const goToAssets = (type) => {
      if (type === 'visitor') {
        router.push({ name: 'Entrance System', })
      } else if (type === 'change') {
        router.push({ name: 'Change Request', })
      } else if (type === 'assetOnly') {
        router.push({ name: 'Assets', })
      } else if (type === 'vendor') {
        router.push({ name: 'Vendors', })
      }
      else {
        router.push({ name: 'Assets', query: { tipe: type }, })
      }
    }
    const checkStatusEntry = (record) => {
      // console.log(record.date)
      const hariIni = new Date();
      const tanggalDiberikan = new Date(record.date);

      // Membandingkan tanggal tanpa mempertimbangkan waktu
      hariIni.setHours(0, 0, 0, 0);
      tanggalDiberikan.setHours(0, 0, 0, 0);

      if (tanggalDiberikan < hariIni) {
        return 'expired';
      } else {
        return 'masih bisa';
      }
    }
    return {
      topVendor,
      settings,
      checkStatusEntry,
      goToAssets,
      changeYear,
      filterYear,
      filterStatusGaransi,
      filteredData,
      search,
      columns,
      columnsReceptionist,
      getStatusWarranty,
      convertPrice,
      handleSearch,
      dataAggregate,
      getCurrentDate,
      // CHART
      chartAssets,
      optionsAssets,
      chartChangeRequest,
      optionsChangeRequest,
      filterCustomer,
      role,
      selectedFilterStatus,
      selectedFilterStatusGaransi,
      dataReceptionist,
      lottie,
      caution,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.th-list-course {
  .th-link {
    color: var(--vb-color-primary) !important;

    &:hover {
      color: var(--kit-color-primary) !important;
    }
  }
}

.text-custom {
  font-style: 'Monstserrat';
  font-weight: 500;
  font-size: 24px;
}

.font-custom {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.font-custom2 {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.font-custom3 {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.number-custom {
  font-style: 'Monstserrat';
  font-weight: 700;
  font-size: 40px;
}
</style>